<template>
  <div class="indexSchool">
    <img style="display: block;width:100%" src="@/assets/imgs/znBG.png" alt="">
    <div class="minw">
      <!-- 筛选条件 -->
      <!-- <div style="cursor: pointer;margin: 20px 0;">
        <span style="color: #3ABDA9;font-size: 16px;">筛选条件：</span> &nbsp;
        <span v-for="(item, index) in searchInfoList" :key="index" class="searchItem">
          {{ item.name }} <span @click="delsearchInfo(index)" style="font-size: 13px;color: #ccc;">X</span>
        </span>
      </div> -->
      <div style="display: flex; align-items: center;margin:10px 0">
        <!-- <div class="searchInput">
          <el-input v-model="collegeName" @keyup.enter.native="sousuo" placeholder="请输入专业名称/意向院校名称/职业名称"></el-input>
          <div class="divicon" @click="sousuo()">
            <i class="el-icon-search" style="color: white"></i>
          </div>
        </div> -->
        <div class="cwbCss">
          <p :class="{ c: status == 1 }" style="border-radius: 20px;">
            <span class="c pItem" v-show="status != 1"></span>

            <span @click="cwbChange(1)" class="cwbtext">
              冲{{ wendData.chong }}
            </span>
          </p>
          <p :class="{ w: status == 2 }" style="border-radius: 20px;">
            <span class="w pItem" v-show="status != 2"></span>

            <span @click="cwbChange(2)" class="cwbtext">
              稳{{ wendData.wen }}
            </span>
          </p>

          <p :class="{ b: status == 3 }" style="border-radius: 20px;">
            <span class="b pItem" v-show="status != 3"></span>

            <span @click="cwbChange(3)" class="cwbtext">
              保{{ wendData.bao }}
            </span>
          </p>

        </div>
        <div class="btndiv">
          <div class="canceldiv" @click="handclikc('fanhui')">返回修改</div>
          <div class="confirmdiv" @click="handclikc('confirm')">生成志愿表</div>
        </div>
          <!-- <div class="volunteerTip">

          【友情提醒】2024年分数和位次为预估，请谨慎参考
        </div> -->
      </div>
      <intellDetails type="ZN" :tableData="tableData"  :batchFile="batchFile" :loading="loading" :yearsList="yearsList">
      </intellDetails>
    </div>
  </div>
</template>

<script>
import {

  addUserScoreTable,
  addUserScoreTables
} from "@/api/aspiration.js";
export default {
  name: 'indexSchool',
  props: ['tableData', 'tableDataCopy', 'yearsList', 'loading', 'numberId', 'wendData', 'pageNum','batchFile'],
  data() {
    return {
      searchInfoList: [
        { name: '985' },
        { name: '公办' },
        { name: '中外合作办学' },
        { name: '百度' },
        { name: '字节' },
        { name: '理工' },
        { name: '医药' },
        { name: '教育部直属' },
      ],
      collegeName: '',
      status: 0
    }
  },
  methods: {
    delsearchInfo(index) {
      this.searchInfoList.splice(index, 1)
    },
    handclikc(val) {
      if (val == 'fanhui') {
        this.$parent.fillingresultFlag = !this.$parent.fillingresultFlag
        this.$parent.animationFlag = false;
        this.$parent.isUpFillingFlag = false
        this.$parent.shujuset()
      } else {

        let arry = []
        this.tableDataCopy.forEach(item => {
          arry.push(item.scorePlansEntity.spId)
        })


        addUserScoreTables({
          phoneNumber: localStorage.getItem('phonenumber'),
          paramsType: 2,
          spIds: arry
          // numberId: this.numberId,
        }).then(
          res => {
            if (res.code == 200) {
              this.$message.success("保存成功")
            }
          }
        )
      }

    },
    btnClick(type) {
      if (type == 'cancel') {
        this.$parent.fillingresultFlag = !this.$parent.fillingresultFlag
        this.$parent.animationFlag = false
      } else if (type == 'confirm') {

      }
    },
    cwbChange(status) {
      if (this.status == status) {
        this.status = 0
        this.$emit('tableFilter', this.tableDataCopy)

      } else {
        this.status = status;
        this.$emit('tableFilter', this.tableDataCopy.filter(item => item.status == status))


      }

    },
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.indexSchool {
  .searchItem {
    border: 1px solid #12b098;
    border-radius: 13px;
    box-sizing: border-box;
    padding: 2px 10px;
    margin: 0 5px;
  }

  .searchInput {
    margin-left: 7px;

    flex: 7;
  }

  .cwbCss {
    width: 30%;

    p {
      width: 31%;
    }
  }


  .btndiv {

    display: flex;
    margin-top: 4px;

    div {
      text-align: center;
      height: 35px;
      line-height: 35px;
      text-align: center;
      width: 120px;
      border-radius: 20px;
      color: white;
      cursor: pointer;
    }

    .canceldiv {
      color: black;
      background: white;
      border: 1px solid #ccc;
      margin-right: 10px;
    }

    .confirmdiv {
      background: #12B098;
    }

  }
}
</style>
